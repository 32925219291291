
/* total width */
::-webkit-scrollbar {
    background-color: transparent;
    width:8px
}
::-webkit-scrollbar:horizontal {
    background-color: transparent;
    height:8px
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: transparent
}
::-webkit-scrollbar-track:hover {
    background-color: transparent
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color:#a0a0a5;
    border-radius:16px;
    border: 1px solid #a0a0a5
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a590;
    border: none
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}